import React from "react"
import classes from "./HeroSlider.module.css"
import SwiperCore, { Navigation, SwiperOptions, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { HeroHeight, HeroItem, HeroStyles } from "../types"
import { Align, HeroSlide, Layout } from "../HeroSlide"
import Rectangle from "../../../shapes/Rectangle"
import { useIsClient } from "../../../../root/punks/core/hooks"

export interface HeroSliderProps {
  noGutters?: boolean
  styles: HeroStyles
  items: HeroItem[]
  layout?: Layout
  align: Align
}

const getRatio = (height: HeroHeight) => {
  switch (height) {
    case "xs":
      return 15
    case "sm":
      return 25
    case "md":
      return 30
    case "lg":
      return 35
    case "full":
      return 50
    case "square":
      return 100
  }
}

const getRatioMobile = (height: HeroHeight) => {
  switch (height) {
    case "xs":
      return 20
    case "sm":
      return 40
    case "md":
      return 60
    case "lg":
      return 70
    case "full":
      return 90
    case "square":
      return 100
  }
}

export const HeroSlider = ({ items, styles, layout }: HeroSliderProps) => {
  const isClient = useIsClient()
  SwiperCore.use([Navigation, Autoplay])
  const settings: SwiperOptions = {
    loop: true,
    speed: 1500,
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
    watchSlidesVisibility: true,
    navigation: items.length > 1,
  }
  return (
    <Rectangle
      className={`${classes.heroRoot} h-full relative`}
      ratio={styles.ratioValue ?? getRatio(styles.height ?? "sm")}
      ratioMobile={styles.ratioValue ?? getRatioMobile(styles.height ?? "sm")}
    >
      {items.length === 1 && (
        <HeroSlide
          item={items[0]}
          layout={layout}
          classes={{
            root: classes.slideRoot,
            content: classes.slideContent,
          }}
          preloadImage
        />
      )}
      {items.length > 1 && (
        <Swiper
          key={isClient ? "client" : "server"}
          style={{ height: "100%" }}
          {...settings}
        >
          {items &&
            items.map((single, i) => {
              return (
                <SwiperSlide key={i}>
                  <HeroSlide
                    item={single}
                    layout={layout}
                    classes={{
                      root: classes.slideRoot,
                      content: classes.slideContent,
                    }}
                    preloadImage={i === 0}
                  />
                </SwiperSlide>
              )
            })}
        </Swiper>
      )}
    </Rectangle>
  )
}

HeroSlider.defaultProps = {
  height: "sm",
  align: "default",
}
