import { imageUrl } from "../../../cms/services/images"
import { ResponsiveHeroProps } from "../../../ui/organisms/Hero/ResponsiveHero"
import { HeroItem, HeroStyles } from "../../../ui/organisms/Hero/types"
import { toCtaData } from "./cta"

const toHeroSlide = (slideItem: any): HeroItem => ({
  cta: toCtaData(slideItem.cta),
  ctaType: slideItem?.ctaType,
  image: {
    asset: slideItem.image,
    url: imageUrl(slideItem.image),
    alt: "",
  },
  imageMobile: {
    asset: slideItem.imageMobile,
    url: imageUrl(slideItem.imageMobile),
    alt: "",
  },
  seoTitle: slideItem.seoTitle,
  videoUrl: slideItem.video?.url,
  title: slideItem.title,
  subtitle: slideItem.subtitle,
  backgroundFull: true,
  negative: slideItem.color !== "black",
})

const toStyles = (styles: any): HeroStyles => ({
  height: styles?.height ?? ("sm" as any),
  layout: styles?.layout ?? ("slider" as any),
  ratioValue: styles?.heightRatio,
  containerMargins: styles?.containerMargins,
})

export const toHeroProps = (item: any): ResponsiveHeroProps => ({
  items: item.data.slides.map(toHeroSlide),
  styles: toStyles(item.data.styles),
  stylesMobile: toStyles(item.data.stylesMobile),
})
