import classNames from "classnames"
import classes from "./HeroSlide.module.css"
import React from "react"
import { SanityNextImg } from "../../../../cms/components"
import ConditionalLink from "../../../utilities/ConditionalLink"
import { HeroItem } from "../types"
import { useAppDispatch } from "../../../../state/store"
import { updatePromoRouletteModalOpenState } from "../../../../features/ui/state/uiActions"

export type Align = "center" | "left" | "right"
export type Layout = "default" | "bottom"

interface HeroClasses {
  root?: string
  content?: string
}

export interface HeroSlideItem {
  item: HeroItem
  layout?: Layout
  align?: Align
  classes?: HeroClasses
  animationDelay: number
  ctaFadeInDelay: number
  preloadImage?: boolean
}

export const HeroSlide = ({
  layout,
  align,
  item,
  classes: userClasses,
  preloadImage,
}: HeroSlideItem) => {
  const dispatch = useAppDispatch()

  const handleClick = () => {
    if (item.ctaType === "promoRoulette") {
      dispatch(updatePromoRouletteModalOpenState(true))
    }
  }

  return (
    <ConditionalLink link={item.cta?.url} noDiv prefetch={false}>
      <div
        className={`${classes.slide} h-full ${
          item.ctaType === "promoRoulette" ? "cursor-pointer" : ""
        }`}
        onClick={handleClick}
      >
        <div className={classNames("inner-img", classes.imgContainer)}>
          {item.videoUrl && (
            <video width="100%" height="100%" autoPlay muted>
              <source src={item.videoUrl} type="video/mp4"></source>
            </video>
          )}
          <SanityNextImg
            className={item.imageMobile?.asset ? "hidden sm:block" : undefined}
            asset={item.image?.asset}
            objectFit="cover"
            objectPosition="center"
            sizes="(max-width: 499px) 499px, 100vw"
            h100
            preload={preloadImage && !item.imageMobile?.asset}
            quality={90}
            alt={item.seoTitle}
            blurUp
          />
          {item.imageMobile?.asset && (
            <SanityNextImg
              className="sm:hidden"
              asset={item.imageMobile.asset}
              objectFit="cover"
              objectPosition="center"
              sizes="(max-width: 499px) 499px, 100vw"
              width={650}
              h100
              preload={preloadImage}
              quality={90}
              alt={item.seoTitle}
              blurUp
            />
          )}
        </div>
        {/* <div className="relative h-full w-50">
          <div
            className={classNames(
              {
                [classes.contentsBottom]: layout === "bottom",
              },
              classes.contents,
              userClasses?.root
            )}
          >
            <div
              className={classNames(
                "hero-contents",
                {
                  "text-right": align === "right",
                  "text-left": align === "left",
                },
                userClasses?.content
              )}
            >
            </div>
          </div>
        </div> */}
      </div>
    </ConditionalLink>
  )
}

HeroSlide.defaultProps = {
  animationDelay: 500,
  ctaFadeInDelay: 500,
  layout: "bottom",
}
