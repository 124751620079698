import classNames from "classnames"
import classes from "./HeroGrid.module.css"
import React from "react"
import {
  CustomBox,
  CustomCol,
  CustomContainer,
  CustomRow,
} from "../../../spacings"
import { HeroSlide } from "../HeroSlide"
import { HeroItem, HeroStyles } from "../types"

export interface HeroGridProps {
  items: HeroItem[]
  styles: HeroStyles
}

export const HeroGrid = ({ items, styles }: HeroGridProps) => {
  return (
    <div
      className={classNames({
        [classes.sizeFull]: styles.height === "full",
        [classes.sizeLg]: styles.height === "lg",
        [classes.sizeMd]: styles.height === "md",
        [classes.sizeSm]: styles.height === "sm",
        [classes.sizeXs]: styles.height === "xs",
      })}
      style={{ height: styles.heightValue }}
    >
      <CustomContainer fluid={!styles.containerMargins} h100>
        <CustomRow h100 spacing>
          <CustomCol xs={12} sm={6}>
            <HeroSlide item={items?.[0]} ctaFadeInDelay={0} preloadImage />
          </CustomCol>
          <CustomCol xs={12} sm={6}>
            <CustomBox className={classes.h50} pb={1}>
              <HeroSlide
                item={items?.[1]}
                animationDelay={1000}
                ctaFadeInDelay={0}
                preloadImage
              />
            </CustomBox>
            <CustomBox className={classes.h50} pt={1}>
              <HeroSlide
                item={items?.[2]}
                animationDelay={1000}
                ctaFadeInDelay={0}
                preloadImage
              />
            </CustomBox>
          </CustomCol>
        </CustomRow>
      </CustomContainer>
    </div>
  )
}
