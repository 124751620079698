import React from "react"
import DesktopOnly from "../../../../root/punks/mui/visibility/DesktopOnly"
import MobileOnly from "../../../../root/punks/mui/visibility/MobileOnly"
import { HeroGrid } from "../HeroGrid"
import { HeroSlider } from "../HeroSlider"
import { HeroItem, HeroStyles } from "../types"

export interface ResponsiveHeroProps {
  items: HeroItem[]
  styles: HeroStyles
  stylesMobile: HeroStyles
}

export const ResponsiveHero = ({
  items,
  styles,
  stylesMobile,
}: ResponsiveHeroProps) => {
  if (
    !stylesMobile.layout ||
    JSON.stringify(stylesMobile ?? {}) === JSON.stringify(styles ?? {})
  ) {
    return styles.layout === "grid" ? (
      <HeroGrid items={items} styles={styles} />
    ) : (
      <HeroSlider items={items} styles={styles} />
    )
  }

  return (
    <>
      <MobileOnly>
        {stylesMobile.layout === "grid" ? (
          <HeroGrid items={items} styles={stylesMobile} />
        ) : (
          <HeroSlider items={items} styles={stylesMobile} align="left" />
        )}
      </MobileOnly>
      <DesktopOnly>
        {styles.layout === "grid" ? (
          <HeroGrid items={items} styles={styles} />
        ) : (
          <HeroSlider items={items} styles={styles} />
        )}
      </DesktopOnly>
    </>
  )
}
