import React from "react"
import { ResponsiveHero } from "../../../../../ui/organisms/Hero/ResponsiveHero"
import { toHeroProps } from "../../../converters/hero"

interface Props {
  content: any
}

const ResponsiveHeroWrapper = ({ content }: Props) => {
  return <ResponsiveHero {...toHeroProps(content.item.hero)} />
}

export default ResponsiveHeroWrapper
